// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/wrappers/StandardPage'
import seoHelper from '../methods/seoHelper'

import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'About Us | Learning Wings Education System',
  nakedPageSlug: 'about',
  pageAbstract:
    "Learning Wings Education System is a leading provider of school management services and works closely with schools to raise student achievements. It's flagship schools – Cambridge International School – spread across North India is showing children the process of realizing their potential.",
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
    cover1: file(relativePath: { eq: "covers/cover1.jpg" }) {
      ...max1500
    }
    cover2: file(relativePath: { eq: "covers/cover2.jpg" }) {
      ...max1500
    }
    cover3: file(relativePath: { eq: "covers/cover3.jpg" }) {
      ...max1500
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className="page" seoData={seoData} {...props}>
    <div className="default-container">
      <h1 class="shadow" data-shadow="Dream. Dare. Do.">
        Dream. Dare. Do.
      </h1>
      <Gallery
        data={props.data}
        lookup="cover"
        columns={{ min: 3, max: 6 }}
        Img={Img}
      />
      <br />
      <div style={{ maxWidth: '50rem' }}>
        <p>
          Learning Wings Education Systems is a leading provider of school
          management services in India, and we work closely with schools to
          raise learner attainments. Our flagship schools – Cambridge
          International School – spread across North India is showing children
          the process of realizing their potential.
        </p>
        <p>
          Innovation and commitment to developing international gold standards
          is what we do best. We are a human, physical and technological
          corporation which serves as a platform to develop the next generation
          of global citizens.
        </p>
        <p>
          We were the first to bring Cambridge International Exams into the
          North Indian Education landscape and the first International
          Baccalaureate School provider in North India. Learning Wings was also
          the first Indian school management service provider to develop
          partnerships with Teacher Training Universities in UK for continuous
          teachers empowerment. These facts show that we live to implement
          strategies that are the physical embodiment of our motto –{' '}
          <strong>Dream, Dare, Do.</strong>
        </p>
        <p>
          Following the success of our schools, now have a flourishing dynamic
          education system comprising of several innovative and high tech
          schools distributed spatially in different locations - all committed
          to the excellence.
        </p>
      </div>
    </div>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
